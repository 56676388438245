/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-30 18:06:27
 * @Module: item 的底部
 */
 <template>
  <div>

    <div class="item-footer-box hover">
      <!-- <div @click="onclick('collect')">
        <span :class="data.has_collect?'iconfont iconshoucang1 has':'iconfont iconshoucang'"></span>
        <span>{{data.collection_count||"收藏"}}</span>
      </div> -->
      <div @click="onclick('transpond')">
        <span class="iconfont iconfenxiang"></span>
        <span>{{data.shared_count||"转发"}}</span>
      </div>
      <div @click="onclick('comment')">
        <span class="iconfont iconpinglun"></span>
        <span>{{(data.comments_count+successCut)||"评论"}}</span>
      </div>
      <div @click="onclick('like')">
        <span class="iconfont"
              :class="data.has_liked?'icondianzanhou has':'icondianzan'"></span>
        <span>{{data.likes_count||"点赞"}}</span>
      </div>
    </div>
    <footer-comment v-if="showCommentId==data.uuid"
                    :data="data"
                    @onsuccess="onsuccess" />
  </div>
</template>
 <script>
import footerComment from "./module/footerComment"
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    showCommentId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      successCut: 0
    };
  },
  components: {
    footerComment
  },
  mounted () { },
  methods: {
    onclick (type) {
      this.$emit('footer-click', { type, data: this.data })
    },
    onsuccess () {
      this.successCut = this.successCut + 1
    }
  },
};
 </script>
 <style lang="scss" scoped>
.item-footer-box {
  height: 46px;
  display: flex;
  div {
    display: flex;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 14px;
    .iconfont {
      margin-right: 6px;
    }
  }
  .has {
    color: #df2a29;
  }
}
</style>