/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 11:21:23
 * @Module: 微头条模块
 */
 <template>
  <div>
    <div>
      <at-text-render :text="data.moment_content.content"
                      :data="data" />
    </div>
    <div v-if="media_infos.length===1">
      <div @click.stop
           style="width: 248px;"
           v-viewer="{title:false}">
        <el-image style="width: 248px;"
                  class="imageItem-border imageItem-marginTop"
                  :src="media_infos[0].src">
        </el-image>
      </div>
    </div>
    <div v-else-if="media_infos.length===4"
         class="imageItem-marginTop">
      <div class="imageItem-images "
           @click.stop
           v-viewer="{title:false}">
        <el-image style="width:212px;height:212px;"
                  class="imageItem-images-itemfour imageItem-border"
                  v-for="(item,index) in media_infos"
                  :key="index"
                  fit="cover"
                  :src="item.src" />
      </div>
    </div>
    <div v-else
         class="imageItem-marginTop">
      <div class="imageItem-images"
           @click.stop
           v-viewer="{title:false}">
        <el-image style="width:140px;height:140px;"
                  class="imageItem-images-item imageItem-border"
                  v-for="(item,index) in media_infos"
                  :key="index"
                  fit="cover"
                  :src="item.src" />
      </div>
    </div>
  </div>
</template>
 <script>
import atTextRender from "@/components/atTextRender"
export default {
  components: {
    atTextRender,
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {

    };
  },
  mounted () {
  },
  methods: {
    onClick () {
      this.$emit("onClick")
    }
  },
  computed: {
    media_infos () {
      return this.data.moment_content.media_infos || []
    }
  }, //计算属性
};
 </script>
 <style lang='scss' scoped>
.imageItem-marginTop {
  margin-top: 9px;
}
.imageItem-images {
  width: 428px;
  &-item:nth-child(3n-1) {
    margin-left: 4px;
    margin-right: 4px;
  }
  &-itemfour:nth-child(2n) {
    margin-left: 4px;
  }
}
.imageItem-border {
  border-radius: 4px;
}
</style>