/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 11:23:12
 * @Module: 小视频模块
 */
 <template>
  <div @click.stop="onClick">
    <at-text-render :text="data.moment_content.content"
                    :data="data" />
    <div style="width:100%;">

      <video :src="data.moment_content.play_address"
             :poster="data.moment_content.video_cover_info?data.moment_content.video_cover_info.src:false"
             width="248px"
             :height="248/ratio+'px'"
             controls
             class="videoItem-content" />
    </div>
  </div>
</template>
 <script>
import atTextRender from "@/components/atTextRender"
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ratio () {
      const { width, height } = this.data.moment_content.video_cover_info
      return width / height
    }
  },
  components: {
    atTextRender
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    onClick () {
      this.$emit("onClick")
    }
  },
};
 </script>
 <style lang='scss' scoped>
.videoItem-content {
  margin-top: 8px;
  border-radius: 4px;
}
</style>