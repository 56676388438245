/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-11 16:58:46
 * @Module: 转发显示模块
 */
 <template>
  <div>
    <div class="transpondModule hover"
         @click.stop="toDetail(data)"
         v-if="data.moment_content.moment_content">
      <div class="transpondModule-content">
        <div class="transpondModule-content-timeIcon el-icon-caret-right"
             v-if="data.moment_content.type === 2"></div>
        <el-image :src="imagesrc"
                  v-if="imagesrc"
                  fit="cover"
                  class="transpondModule-content-l" />
        <!-- 非课程 -->
        <div class="transpondModule-content-r"
             v-if="data.moment_content.type !==5">
          <div class="transpondModule-content-r-t">
            <div class="transpondModule-content-r-t-l">
              <el-avatar :size="26"
                         :src="data.moment_content.user_avatar"></el-avatar>
            </div>
            <div class="transpondModule-content-r-t-r">{{data.moment_content.user_name}}</div>
          </div>
          <div class="transpondModule-content-r-b ellipsis2"
               :style="imagesrc?'width:420px;':'width:100%;'">
            <atTextRender :text="text"
                          :data="data.moment_content" />
          </div>
        </div>
        <!-- 课程 -->
        <div class="transpondModule-content-r transpondCollege"
             v-else>
          <div class="transpondCollege-title ellipsis">{{data.moment_content.moment_content.title|trimSpace}}</div>
          <div class="transpondCollege-content ellipsis2">{{data.moment_content.moment_content.plain_description|trimSpace}}</div>
        </div>
      </div>
    </div>
    <div v-else>该内容已被删除</div>
  </div>
</template>
 <script>
import atTextRender from "@/components/atTextRender"
export default {
  components: {
    atTextRender
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    toDetail (data) {
      const { moment_content: { type, uuid, moment_content: { uuid: course_id } } } = data
      // const {type:data.moment_content.type,uuid:data.moment_content.uuid} = data
      this.$store.dispatch("homepage/toDetail", {
        type, uuid, query: {
          course_id
        }
      })
    }
  },
  computed: {
    imagesrc () {
      if (this.data.moment_content.type === 0) {
        return this.data.moment_content.moment_content.medias[0]
      } else if (this.data.moment_content.type === 1) {
        return this.data.moment_content.moment_content.images ? this.data.moment_content.moment_content.images[0] : ''
      } else if (this.data.moment_content.type === 2) {
        return this.data.moment_content.moment_content.video_cover
      } else if (this.data.moment_content.type === 4) {
        return this.data.moment_content.moment_content.video_cover
      } else if (this.data.moment_content.type === 5) {
        return this.data.moment_content.moment_content.cover
      } else {
        return ''
      }
    },
    text () {
      if (this.data.moment_content.type === 0) {
        return this.data.moment_content.moment_content.content
      } else if (this.data.moment_content.type === 1) {
        return this.data.moment_content.moment_content.title
      } else if (this.data.moment_content.type === 2) {
        return this.data.moment_content.moment_content.title
      } else if (this.data.moment_content.type === 3) {
        return this.data.moment_content.moment_content.shareable_description
      } else {
        return ''
      }
    }
  }, //计算属性
};
 </script>
 <style lang='scss' scoped>
.transpondModule-content {
  margin-top: 8px;
  width: 632px;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  position: relative;
  &-l {
    width: 158px;
    height: 102px;
    border-radius: 4px;
  }
  &-r {
    margin-left: 14px;
    // padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &-t {
      display: flex;
      align-items: center;
      &-l {
        height: 26px;
      }
      &-r {
        line-height: 26px;
        margin-left: 4px;
        color: #5071a5;
        font-size: 18px;
      }
    }
    &-b {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &-timeIcon {
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 40px;
    position: absolute;
    z-index: 2;
    left: 79px;
    bottom: 0px;
    top: 0px;
    margin: auto;
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 30px;
  }
}
.transpondCollege {
  &-title {
    width: 441px;
    font-size: 18px;
    color: #262626;
    line-height: 25px;
  }
  &-content {
    width: 441px;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    height: 40px;
  }
}
</style>