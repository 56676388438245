/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-30 14:25:52
 * @Module: 文档流内容 
 */
 <template>
  <div class="item-box">
    <itemheader :data="data"
                :personal="personal"
                v-show="showHeader"
                @header-click="res=>$emit('header-click',{type:res,item:data,index:itemindex})" />
    <div class="item-box-text hover"
         @click="toDetail(data)">
      <image-item v-if="data.type===0"
                  :data="data"
                  @onClick="toDetail(data,true)" />
      <article-item v-if="data.type===1"
                    :data="data" />
      <video-item v-if="data.type===2"
                  :data="data"
                  @onClick="toDetail(data)" />
      <short-video-item v-if="data.type===4"
                        :data="data"
                        @onClick="toDetail(data)" />
      <transpond-item v-if="data.type===3"
                      @onClick="toDetail(data)"
                      :data="data" />
      <collegeItem v-if="data.type===5"
                   @onClick="toDetail(data)"
                   :data="data" />
    </div>
    <itemfooter :data="data"
                :showCommentId="showCommentId"
                @footer-click="res=>$emit('footer-click',{...res,index:itemindex})" />
  </div>
</template>
 <script>
import itemheader from "./header"
import itemfooter from "./footer"
import imageItem from "./module/imageItem"
import articleItem from "./module/articleItem"
import videoItem from "./module/videoItem"
import shortVideoItem from "./module/shortVideoItem"
import transpondItem from "./module/transpondItem"
import collegeItem from "./module/collegeItem"

export default {
  components: {
    itemheader,
    itemfooter,
    imageItem,
    articleItem,
    videoItem,
    shortVideoItem,
    transpondItem,
    collegeItem
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    itemindex: {
      type: Number
    },
    personal: {
      type: Boolean,
      defaule: false
    },
    showCommentId: {
      type: String,
      defaule: ""
    }
  },
  data () {
    return {

    };
  },
  computed: {
    showHeader () {
      if (this.data.type == 5) {
        return false
      } else {
        return true
      }
    }
  },
  mounted () { },
  methods: {
    toDetail (res, bubble) {
      if (!bubble)
        this.$store.dispatch("homepage/toDetail", {
          ...res, query: {
            course_id: res.moment_content.uuid
          }
        })
    }
  },

};
 </script>
 <style lang="scss" scoped>
.item-box {
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  &-text {
    font-family: PingFang SC;
    padding: 0px 70px 0px 80px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>