/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-30 14:40:21
 * @Module:item 的头部 
 */
 <template>
  <div class="item-header-box"
       @click="
      $store.dispatch('homepage/toPersonal', {
        uuid: data.user_id,
        newPage: true
      })
    ">
    <div style="position:relative;">
      <el-avatar :size="50"
                 :src="data.user_avatar"
                 class="hover"> </el-avatar>
      <img src="@/static/img/renzheng.gif"
           alt=""
           v-if="data.user_verify"
           style="width:12px;height:12px;position:absolute;bottom:1px;right:4px;" />
    </div>
    <div class="tem-header-content">
      <div class="tem-header-content-l">
        <div>
          <span :class="data.is_vip ? 'new-color' : ''">{{
            data.user_name
          }}</span>
          <img src="@/static/img/vip.gif"
               alt=""
               v-if="data.is_vip"
               style="margin-left:5px;width:12px;height:12px;" />
          <img src="@/static/img/broker.png"
               alt=""
               v-if="data.is_super_broker"
               style="margin-left:5px;width:12px;height:12px;" />
        </div>
        <div style="margin-top:4px;">{{ data.created_at }}<span v-if="data.user_verify">·{{data.user_verify}}</span></div>
      </div>
      <div class="tem-header-content-r hover"
           @click.stop="follow_click('follow')"
           v-show="!data.has_followed && !personal">
        + 关注
      </div>
      <div v-show="personal && is_myself"
           @click.stop="follow_click('delete')"
           class="tem-header-content-r hover">
        删除
      </div>
    </div>
  </div>
</template>
 <script>
import userInfo from "@/mixins/userinfo";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    personal: {
      type: Boolean,
      defaule: false
    }
  },
  mixins: [userInfo],
  data () {
    return {};
  },
  components: {},
  mounted () { },
  methods: {
    follow_click (type) {
      this.$emit("header-click", type);
    }
  }
};
</script>
 <style lang="scss" scoped>
.item-header-box {
  height: 50px;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.tem-header-content {
  padding-left: 10px;
  padding-right: 70px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  height: 100%;
  &-l {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 20px;
    div:nth-child(2) {
      font-size: 12px;
      color: #999999;
      margin-top: 4px;
      line-height: 17px;
    }
  }
  &-r {
    color: #df2a29;
  }
}
.new-color {
  color: #df2a29;
}
</style>