/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-23 10:40:19
 * @Module: item 底部的评论模块
 */
 <template>
  <div class="footerComment"
       v-loading="loading">
    <div class="footerComment-top">
      <div class="footerComment-top-input"
           v-if="userInfo">
        <el-avatar :size="40"
                   :src="userInfo.avatar"
                   class="hover"> </el-avatar>
        <Mentionable :keys="['@']"
                     :items="items"
                     insert-space
                     ref="at"
                     style="width:781px"
                     offset="6">
          <el-input v-model="content"
                    type="textarea"
                    id="comment-input"
                    placeholder="输入回复的内容"
                    style="margin-left:20px;" />
          <template #item-@="{ item }">
            <span style="padding:6px 0px"
                  class="dim">
              {{ item.value }}
            </span>
          </template>
        </Mentionable>
      </div>
      <el-button type="text"
                 v-else
                 style="width:100%;padding:20px;"
                 @click="to_login">请先登录才能发表评论</el-button>
      <div class="footerComment-top-btn"
           v-if="userInfo">
        <el-tag closable
                v-show="parent_name"
                size="mini"
                style="margin-right:10px;"
                @close="clearReply">
          @{{parent_name}}
        </el-tag>
        <emojis @getEmo="getEmo" />
        <el-button type="primary"
                   size="mini"
                   @click="_set_comment"
                   plain>发送</el-button>
      </div>
      <div class="comment-list-box">
        <div class="comment-list"
             v-for="item in commentList"
             :key="item.comment_id">
          <div class="comment-list-avatar"
               style="position:relative;"
               @click="goInfo(item.user_id)">
            <el-avatar :size="40"
                       :src="item.user_avatar"></el-avatar>
            <img src="@/static/img/renzheng.gif"
                 alt=""
                 v-if="!(item.user_verify == null)"
                 style="width:12px;height:12px;position:absolute;bottom:1px;right:4px;z-index:100" />
          </div>
          <div class="comment-list-content">
            <div class="comment-list-content-t">
              <div style="margin-bottom:4px">
                <span :class="item.user_is_vip ? 'new-color' : ''">{{
                item.user_name
              }}</span>
              </div>
              <span>{{ item.created_at }}</span>
            </div>
            <div class="comment-list-content-c">
              <atTextRender :text="item.content"
                            :data="item" />
            </div>
            <div class="comment-list-content-b">
              <span class="hover">
                <span @click="reply(item.comment_id,item.user_name,item.comment_id)"
                      v-if="item.type !=='like'">回复</span>
                <span v-show="childData[item.comment_id]"
                      @click="clearCommentChild(item.comment_id)">·收起回复</span>
                <span v-show="!childData[item.comment_id]&&item.descendants_count"
                      @click="_get_commentChild(item.comment_id)">·{{item.descendants_count}}条回复</span>
              </span>
              <i class="el-icon-arrow-down"
                 v-show="item.descendants_count"></i>
            </div>
            <div v-if="childData[item.comment_id]">
              <div class="comment-list-content-child"
                   v-for="itm in childData[item.comment_id]"
                   :id="'COMENT' + itm.comment_id"
                   :key="itm.comment_id">
                <div class="comment-list-content-child-avatar">
                  <el-avatar :size="40"
                             :src="itm.user_avatar"></el-avatar>
                  <img src="@/static/img/renzheng.gif"
                       alt=""
                       v-if="itm.has_verify"
                       style="width:12px;height:12px;position:absolute;bottom:42px;right:4px;z-index:100" />
                </div>
                <div class="comment-list-content-child-content">
                  <div class="comment-list-content-child-content-t"
                       style="margin-bottom:4px">
                    <span :class="itm.user_is_vip ? 'new-color' : ''">{{
                    itm.user_name
                  }}</span>
                    <span>{{ itm.created_at }}</span>
                  </div>
                  <div class="comment-list-content-child-content-c">
                    <span style="color:#406599"
                          v-if="itm.parent_user !== item.user_name">@{{ itm.parent_user }}：</span>
                    <atTextRender :text="itm.content"
                                  :data="itm" />
                  </div>
                  <div class="comment-list-content-child-content-b">
                    <span class="hover"
                          v-if="itm.type !=='like'"
                          @click="
                      reply(itm.comment_id, itm.user_name, item.comment_id)
                    ">回复</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-button type="text"
                 v-if="commentList.length>=9"
                 style="width:100%;padding:20px;"
                 @click="commentClick">查看更多评论</el-button>
    </div>
  </div>
</template>
 <script>
import userinfo from "@/mixins/userinfo"
import commentMixins from "@/mixins/commentMixins"
import emojis from "@/components/emojis"
import emojiMixins from "@/components/emojis/emojiMixins"
export default {
  components: {
    emojis
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [userinfo, commentMixins, emojiMixins],
  data () {
    return {

    };
  },
  created () {
    this._get_list();
  },
  computed: {
    commentList () {
      return this.list.slice(0, 9)
    }
  },
  methods: {
    to_login () {
      this.$store.commit("layouts/changeLoginVisible", true);
    },
    // 发起评论成功
    set_comment_success () {
      if (this.comment_id) {
        this._get_commentChild(this.comment_id);
      } else {
        this._get_list(this.pagination.current_page);
      }
      this.$emit("onsuccess")
      this.content = "";
      this.clearReply();
    },
    //查看更多评论
    commentClick () {
      const { type, uuid } = this.data
      this.$store.dispatch("homepage/toDetail", {
        type,
        uuid,
        query: { first_in_comment: true }
      })
    }
  },
};
 </script>
 <style lang='scss' scoped>
.footerComment {
  width: 100%;
  min-height: 100px;
  background: #fbfbfb;
  box-shadow: inset 0px 15px 10px -15px #ccc;
  // inset 0px -15px 10px -15px #ccc;
  &-top {
    &-input {
      padding: 20px;
      display: flex;
      align-items: center;
    }
    &-btn {
      padding-right: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.comment-list-box {
  padding: 0 20px 20px;
}
.comment-list {
  display: flex;
  margin-bottom: 16px;
  &-avatar {
    width: 40px;
    height: 40px;
    position: relative;
  }
  &-content {
    min-height: 85px;
    margin-left: 10px;
    &-t {
      margin-top: 7px;
      color: #999999;
      font-size: 12px;
      span:first-child {
        color: #406599;
        font-size: 14px;
      }
    }
    &-c {
      width: 790px;
      margin-top: 12px;
      font-size: 14px;
      color: #333;
      line-height: 20px;
    }
    &-b {
      margin-top: 8px;
      font-size: 12px;
      color: #5071a5;
    }
    &-child {
      margin-top: 16px;
      display: flex;
      &-content {
        min-height: 85px;
        margin-left: 10px;
        &-t {
          margin-top: 7px;
          color: #999999;
          font-size: 12px;
          span:first-child {
            color: #406599;
            font-size: 14px;
          }
        }
        &-c {
          margin-top: 12px;
          font-size: 14px;
          color: #333;
          line-height: 20px;
        }
        &-b {
          margin-top: 8px;
          font-size: 12px;
          color: #5071a5;
        }
      }
    }
  }
  &-pagination {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.new-color {
  color: #df2a29 !important;
}
</style>