/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 14:13:08
 * @Module: 转发模块
 */
 <template>
  <div class="transpondItem-box">
    <div class="transpondItem-title">
      <at-text-render :text="data.moment_content.shareable_description"
                      :data="data" />
    </div>
    <transpond-module :data="data" />
  </div>
</template>
 <script>
import transpondModule from "@/views/homepage/module/transpondModule"
import atTextRender from "@/components/atTextRender"
export default {
  components: {
    transpondModule,
    atTextRender
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},

};
 </script>
 <style lang='scss' scoped>
.transpondItem-box {
}
</style>